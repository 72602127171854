import React from "react";
import Logo from "../../assets/img/logo.png";
// import Navbar from "../../components/Navbar";

function Home() {
  return (
    <div className="w-full h-screen flex flex-col justify-center items-center">
      <img alt="logo" src={Logo} className="h-1/2 object-cover" />
      {/* <Navbar /> */}
      <h1 className="flex flex-col justify-center items-center p-5 bg-primary rounded-lg shadow-lg w-[500px] max-w-[80%]">
        <a
          href="mailto:elisha.wongys@gmail.com"
          className="md:text-3xl text-white font-extrabold"
        >
          elisha.wongys@gmail.com
        </a>
      </h1>
      <h2 className="text-sm uppercase mt-5 w-[500px] max-w-[80%]">
        A fullstack developer with solid hands-on experience
      </h2>
    </div>
  );
}

export default Home;
